
.node {
    cursor: pointer;
}

.node circle {
    fill: #fff;
    stroke: steelblue;
    stroke-width: 3px;
}

.node .circle-valid {
    stroke: greenyellow;
}

.node .circle-invalid {
    stroke: red;
}

.node .circle-highlighted {
    stroke: #62a003;
}

.node text {
    font: 12px sans-serif;
}

.link {
    fill: none;
    stroke: #ccc;
    stroke-width: 2px;
}
.message-item:nth-child(2n) {
    background-color: #e4e4e4;
}